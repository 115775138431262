import Volt from "@assets/img/Volt.svg";
import GShop from "@assets/img/G-shop.svg";
import Qadam from "@assets/img/Qadam.svg";
import Lerna from "@assets/img/Lerna.svg";
import Aiko from "@assets/img/Aiko.svg";

import WellTravel from "@assets/img/WellTravel.svg";
import LG from "@assets/img/LG.svg";
import TravelCompany from "@assets/img/TravelCompany.svg";
import RioMobile from "@assets/img/RioMobile.svg";
import Tstore from "@assets/img/Tstore.svg";
import KiwiStore from "@assets/img/KiwiStore.svg";
import RichTour from "@assets/img/RichTour.svg";
import Tmadoraema from "@assets/img/Tmadoraema.svg";
import Amag from "@assets/img/Amag.svg";
import ApolloTravel from "@assets/img/ApolloTravel.svg";

import Technohome from "@assets/img/Technohome.svg";
import InGame from "@assets/img/InGame.svg";
import PrepUz from "@assets/img/PrepUz.svg";
import Noutbookoff from "@assets/img/Noutbookoff.svg";
import Muntazir from "@assets/img/Muntazir.svg";
import EOne from "@assets/img/E-one.svg";
import TexnoMobile from "@assets/img/TexnoMobile.svg";
import MacBro from "@assets/img/MacBro.svg";
import UstoYakub from "@assets/img/UstoYakub.svg";
import LabbayTravel from "@assets/img/LabbayTravel.svg";
import AisHome from "@assets/img/AisHome.svg";
import VMF from "@assets/img/VMF.svg";
import Globus from "@assets/img/Globus.svg";
import DaivaCasa from "@assets/img/DaivaCasa.svg";
import LkatShah from "@assets/img/LkatShah.svg";
import CapitalWatches from "@assets/img/CapitalWatches.svg";
import Midea from "@assets/img/Midea.svg";
import PoletTravel from "@assets/img/PoletTravel.svg";
import ScmGroup from "@assets/img/ScmGroup.svg";
import StarTime from "@assets/img/StarTime.svg";
import EliteMagicTravel from "@assets/img/EliteMagicTravel.svg";
import Astrum from "@assets/img/Astrum.svg";
import Avtoraqamla from "@assets/img/Avtoraqamla.svg";
import BonjourTravel from "@assets/img/BonjourTravel.svg";
import Charogon from "@assets/img/Charogon.svg";
import ComfortMobile from "@assets/img/ComfortMobile.svg";
import DinaTour from "@assets/img/DinaTour.svg";
import EsElsatour from "@assets/img/EsElsatour.svg";
import EMIRMX7 from "@assets/img/EMIRMX7.svg"
import EuroTour from "@assets/img/EuroTour.svg";
import BluePlane from "@assets/img/BluePlane.svg";
import MuddatliTonirovka from "@assets/img/MuddatliTonirovka.svg";
import ProNasiya from "@assets/img/ProNasiya.svg";
import DreamPhone from "@assets/img/DreamPhone.svg";
import KanDentalClinic from "@assets/img/KanDentalClinic.svg";
import Com from "@assets/img/1Com.svg";
import SF from "@assets/img/SF.svg";
import SpaceAcademy from "@assets/img/SpaceAcademy.svg";
import TripWise from "@assets/img/TripWise.svg";
import Portofino from "@assets/img/Portofino.svg";
import ZavqTravel from "@assets/img/ZavqTravel.svg";


export const sliderContent = [
  {
    img: {
      src: Volt,
      alt: "110VOLT",
    },
  },
  {
    img: {
      src: GShop,
      alt: "G-shop",
    },
  },
  {
    img: {
      src: Qadam,
      alt: "Qadam",
    },
  },
  {
    img: {
      src: Lerna,
      alt: "Lerna",
    },
  },
  {
    img: {
      src: Aiko,
      alt: "AIKO",
    },
  },

  {
    img: {
      src: WellTravel,
      alt: "Well Travel",
    },
  },
  {
    img: {
      src: LG,
      alt: "LG",
    },
  },
  {
    img: {
      src: TravelCompany,
      alt: "Travel Company",
    },
  },
  {
    img: {
      src: RioMobile,
      alt: "Rio Mobile",
    },
  },
  {
    img: {
      src: Tstore,
      alt: "Tstore",
    },
  },
  {
    img: {
      src: KiwiStore,
      alt: "Kiwi Store",
    },
  },
  {
    img: {
      src: RichTour,
      alt: "Rich Tour",
    },
  },
  {
    img: {
      src: Tmadoraema,
      alt: "Tmadoraema",
    },
  },
  {
    img: {
      src: Amag,
      alt: "Amag",
    },
  },
  {
    img: {
      src: ApolloTravel,
      alt: "Apollo Travel",
    },
  },

  {
    img: {
      src: Technohome,
      alt: "Technohome",
    },
  },
  {
    img: {
      src: InGame,
      alt: "In Game",
    },
  },
  {
    img: {
      src: PrepUz,
      alt: "Prep.uz",
    },
  },
  {
    img: {
      src: Noutbookoff,
      alt: "Noutbookoff",
    },
  },
  {
    img: {
      src: Muntazir,
      alt: "Muntazir",
    },
  },
  {
    img: {
      src: EOne,
      alt: "E-ONE",
    },
  },
  {
    img: {
      src: TexnoMobile,
      alt: "Texno Mobile",
    },
  },
  {
    img: {
      src: MacBro,
      alt: "MacBro",
    },
  },
  {
    img: {
      src: UstoYakub,
      alt: "Usto Yakub",
    },
  },
  {
    img: {
      src: LabbayTravel,
      alt: "Labbay Travel",
    },
  },
  {
    img: {
      src: AisHome,
      alt: "Ais Home",
    },
  },
  {
    img: {
      src: VMF,
      alt: "VMF",
    },
  },
  {
    img: {
      src: Globus,
      alt: "Globus",
    },
  },
  {
    img: {
      src: DaivaCasa,
      alt: "Daiva Casa",
    },
  },
  {
    img: {
      src: LkatShah,
      alt: "Lkat Shah",
    },
  },
  {
    img: {
      src: CapitalWatches,
      alt: "Capital Watches",
    },
  },
  {
    img: {
      src: Midea,
      alt: "Midea",
    },
  },
  {
    img: {
      src: PoletTravel,
      alt: "Polet Travel",
    },
  },
  {
    img: {
      src: ScmGroup,
      alt: "SCM GROUP",
    },
  },
  {
    img: {
      src: StarTime,
      alt: "STAR TIME",
    },
  },
  {
    img: {
      src: EliteMagicTravel,
      alt: "Elite Magic Travel",
    },
  },
  {
    img: {
      src: Astrum,
      alt: "Astrum",
    },
  },
  {
    img: {
      src: Avtoraqamla,
      alt: "Avtoraqamla",
    },
  },
  {
    img: {
      src: BonjourTravel,
      alt: "Bonjour Travel",
    },
  },
  {
    img: {
      src: Charogon,
      alt: "Charogon",
    },
  },
  {
    img: {
      src: DinaTour,
      alt: "Dina Tour",
    },
  },
  {
    img: {
      src: EsElsatour,
      alt: "ES Elsatour",
    },
  },
  {
    img: {
      src: EMIRMX7,
      alt: "EMIRMX7"
    }
  },
  {
    img: {
      src: EuroTour,
      alt: "Eur Tour",
    },
  },
  {
    img: {
      src: BluePlane,
      alt: "Blue Plane",
    },
  },
  {
    img: {
      src: MuddatliTonirovka,
      alt: "Muddatli Tonirovka",
    },
  },
  {
    img: {
      src: ComfortMobile,
      alt: "Comfort Mobile",
    },
  },
  {
    img: {
      src: ProNasiya,
      alt: "Pro Nasiya",
    },
  },
  {
    img: {
      src: DreamPhone,
      alt: "Dream Phone",
    },
  },
  {
    img: {
      src: KanDentalClinic,
      alt: "Kan Dental Clinic",
    },
  },
  {
    img: {
      src: Com,
      alt: "1 Com",
    },
  },
  {
    img: {
      src: SF,
      alt: "SF",
    },
  },
  {
    img: {
      src: SpaceAcademy,
      alt: "Space Academy",
    },
  },
  {
    img: {
      src: TripWise,
      alt: "Trip Wise",
    },
  },
  {
    img: {
      src: Portofino,
      alt: "Portofino",
    },
  },
  {
    img: {
      src: ZavqTravel,
      alt: "Zavq Travel",
    },
  },
];
