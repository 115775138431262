import React, { useState } from "react";
import "./Header.scss";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

import PaylaterLogo from "@assets/img/paylater-logo.svg";
import BuisnessIcon from "@assets/img/buisnessIcon.svg";
import ClientIcon from "@assets/img/client-icon.svg";
import UzFlag from "@assets/img/uzFlag.png";
import RussianFlag from "@assets/img/russianFlag.png";
import logInIcon from "@assets/img/logInIcon.svg";
import burgerMenu from "@assets/img/burgerMenu.svg";
import burgerMenuExit from "@assets/img/burgerexit.svg";

import { useGlobalState } from "@/GlobalStateContext";

import { lkUrl, backOfficeUrl } from "@services/api";

function Header() {
  const [menuState, setMenuState] = useState(false);
  const { language, setLanguage, siteVersion, setSiteVersion } =
    useGlobalState();
  const { t: tSiteVersion } = useTranslation([`${siteVersion}`]);
  const { t: tCommon } = useTranslation(["common"]);

  const menuController = (actionType) => {
    if (actionType === "open") setMenuState(true);
    else setMenuState(false);
  };

  const link = siteVersion === "client" ? lkUrl : backOfficeUrl;

  return (
    <div className="header-container">

      <div className="header-buttons mob-vers">
        {language === "ru" ? (
          <button className="mob-btn" onClick={() => setLanguage("uz")}>
            <img
              src={UzFlag}
              alt="uz-flag"
              className="desc-btn__lang"
              draggable="false"
            />
            UZ
          </button>
        ) : (
          <button className="mob-btn" onClick={() => setLanguage("ru")}>
            <img
              src={RussianFlag}
              alt="uz-flag"
              className="desc-btn__lang"
              draggable="false"
            />
            RU
          </button>
        )}
      </div>

      <img className="mob-icon" src={PaylaterLogo} alt="paylater-logo" />

      <div className="header-separating-container">
        <img src={PaylaterLogo} alt="paylater-logo" />
        <div className="header-anchors-container">
          <div className="header-anchor">
            <Link to="benefits" smooth>{tCommon("anchors.benefits")}</Link>
          </div>
          <div className="header-anchor">
            <Link to="partner-shops" smooth>{tCommon("anchors.partnerShops")}</Link>
          </div>
          <div className="header-anchor">
            <Link to="banner" smooth>{tCommon("anchors.banner")}</Link>
          </div>
          <div className="header-anchor">
            <Link to="howItWork" smooth>FAQ</Link>
          </div>
        </div>
      </div>

      <div className="header-buttons">
        {siteVersion === "client" ? (
          <button className="desc-btn" onClick={() => setSiteVersion("business")}>
            <img src={BuisnessIcon} alt="buisness-icon" />
            {tSiteVersion("button.text")}
          </button>
        ) : (
          <button className="desc-btn" onClick={() => setSiteVersion("client")}>
            <img src={ClientIcon} alt="client-icon" />
            {tSiteVersion("button.text")}
          </button>
        )}
        {language === "ru" ? (
          <button className="desc-btn" onClick={() => setLanguage("uz")}>
            <img
              src={UzFlag}
              alt="uz-flag"
              className="desc-btn__lang"
              draggable="false"
            />
            UZ
          </button>
        ) : (
          <button className="desc-btn" onClick={() => setLanguage("ru")}>
            <img
              src={RussianFlag}
              alt="uz-flag"
              className="desc-btn__lang"
              draggable="false"
            />
            RU
          </button>
        )}
        <a href={link} target="_blank" rel="noreferrer" id="enter-lk">
          <button className="header-login-button desc-btn">
            <img src={logInIcon} alt="login-icon" />
          </button>
        </a>
        <button className={`burger-btn ${menuState ? "active" : ""}`} onClick={() => menuController("open")}>
          <img src={burgerMenu} alt="burgerMenu" />
        </button>
        <button className={`burger-exit-btn ${menuState ? "" : "active"}`} onClick={() => menuController()}>
          <img src={burgerMenuExit} alt="burgerMenuExit" />
        </button>
      </div>

      <div className={`mobile-menu ${menuState ? "open" : ""}`}>
        <div className="header-buttons">
          {siteVersion === "client" ? (
            <button className="mob-btn" onClick={() => { setSiteVersion("business"); menuController()}}>
              <img src={BuisnessIcon} alt="buisness-icon" />
              {tSiteVersion("button.text")}
            </button>
          ) : (
            <button className="mob-btn" onClick={() => { setSiteVersion("client"); menuController()}}>
              <img src={ClientIcon} alt="client-icon" />
              {tSiteVersion("button.text")}
            </button>
          )}
        </div>

        <div className="menu-links">
          <div className="menu-link" onClick={() => { menuController()}}>
            <Link to="benefits" smooth> {tCommon("anchors.benefits")}</Link>
          </div>
          <div className="menu-link" onClick={() => { menuController()}}>
            <Link to="partner-shops" smooth>{tCommon("anchors.partnerShops")}</Link>
          </div>
          <div className="menu-link" onClick={() => { menuController()}}>
            <Link to="banner" smooth>{tCommon("anchors.banner")}</Link>
          </div>
          <div className="menu-link" onClick={() => { menuController()}}>
            <Link to="howItWork" smooth>FAQ</Link>
          </div>
        </div>

        <div className="header-buttons login-btn">
          <a href={link} target="_blank" rel="noreferrer" id="enter-lk">
            <button className="mob-btn">
              <img src={logInIcon} alt="loginIcon-icon" />
              {tCommon("login")}
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
