import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { sliderContent } from "./PartnersCarouselContent";
import "./PartnersCarousel.scss";

import Slider from "react-slick";

import { useGlobalState } from "@/GlobalStateContext";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  draggable: false,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        variableWidth: true,
      },
    },
  ],
};

function PartnersCarousel() {
  const sliderRef = useRef(null);
  const isInitialMount = useRef(true);

  const { siteVersion } = useGlobalState();
  const { t: tSiteVersion } = useTranslation([`${siteVersion}`]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      sliderRef.current.slickGoTo(0);
    }
  }, [siteVersion]);

  return (
    <div className="partners-carousel-container" id="partner-shops">
      <div>
        <h2>{tSiteVersion("partnersCarousel.title")}</h2>
        <Slider ref={sliderRef} {...settings}>
          {sliderContent?.map((item, index) => (
            <div className="slider-item" key={index}>
              {item.img && <img src={item.img.src} alt={item.img.alt} />}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default PartnersCarousel;
