import React from "react";
import { useTranslation } from "react-i18next";
import "./Banner.scss";

import BannerPerson from "@assets/img/bannerPerson.png";

import { useGlobalState } from "@/GlobalStateContext";

const Banner = () => {
  const { siteVersion } = useGlobalState();
  const { t: tSiteVersion } = useTranslation([`${siteVersion}`]);

  return (
    <div className={`${siteVersion === "client" ? "banner-container" : "banner-container hide"}`} id="banner">
      {siteVersion === "client" && (
        <>
          <img src={BannerPerson} alt="BannerPerson" />
          <div className="banner-benefits">
            <h1>{tSiteVersion("banner.title")}</h1>
            <p dangerouslySetInnerHTML={{ __html: tSiteVersion("banner.description") }} />
            <p className="s-size-text">{tSiteVersion("banner.caption")}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default Banner;
