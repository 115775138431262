import React from "react";
import { useTranslation } from "react-i18next";
import "./Benefits.scss";

import Like from "@assets/img/Like.svg";
import Pallete from "@assets/img/Pallete.svg";
import Calendar from "@assets/img/Calendar.svg";
import BenefitInstruction from "@assets/img/benefit-instruction.png";
import BenefitInstructionUz from "@assets/img/benefit-instruction-uz.png";
import UserHeart from "@assets/img/User-Heart.svg";
import Earth from "@assets/img/Earth.svg";
import UsersGroup from "@assets/img/Users-Group-Two-Rounded.svg";
import Master from "@assets/img/Master.png";
import MasterUz from "@assets/img/MasterUz.png";

import { useGlobalState } from "@/GlobalStateContext";

function Benefits() {
  const { language, siteVersion } = useGlobalState();
  const { t: tSiteVersion } = useTranslation([`${siteVersion}`]);

  return (
    <div className="benefits-block" id="benefits">
      <div className="benefit-list">
        {siteVersion === "client" ? (
          <>
            <div className="block-shape benefit">
              <img src={Like} alt="Like" />
              {tSiteVersion("benefits.cards.0")}
            </div>
            <div className="block-shape benefit">
              <img src={Pallete} alt="Pallete" />
              {tSiteVersion("benefits.cards.1")}
            </div>
            <div className="block-shape benefit">
              <img src={Calendar} alt="Calendar" />
              {tSiteVersion("benefits.cards.2")}
            </div>
          </>
        ) : (
          <>
            <div className="block-shape benefit">
              <img src={UsersGroup} alt="UsersGroup" />
              {tSiteVersion("benefits.cards.0")}
            </div>
            <div className="block-shape benefit">
              <img src={UserHeart} alt="UserHeart" />
              {tSiteVersion("benefits.cards.1")}
            </div>
            <div className="block-shape benefit">
              <img src={Earth} alt="Earth" />
              {tSiteVersion("benefits.cards.2")}
            </div>
          </>
        )}
      </div>
      <div className="shape instruction-block">
        <div className="benefits-text-block">
          <h2>{tSiteVersion("benefits.banner.title")}</h2>
          <p dangerouslySetInnerHTML={{ __html: tSiteVersion("benefits.banner.description") }} />
        </div>
        {siteVersion === "client" ? (
          <>
            {language === "ru" ? (
              <div className="benefits-img-block">
                <img src={BenefitInstruction} alt="benefit-instruction" />
              </div>
            ) : (
              <div className="benefits-img-block">
                <img src={BenefitInstructionUz} alt="benefit-instruction" />
              </div>
            )}
          </>
        ) : (
          <>
            {language === "ru" ? (
              <div className="benefits-img-block">
                <img src={Master} alt="benefit-instruction" />
              </div>
            ) : (
              <div className="benefits-img-block">
                <img src={MasterUz} alt="benefit-instruction" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default Benefits;
