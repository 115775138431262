import React, { useState, useEffect } from "react";

import { BrowserRouter } from "react-router-dom";

import Preloader from "@Common/Preloader/Preloader";
import Header from "@Common/Header/Header";
import PromoBlock from "@Common/PromoBlock/PromoBlock";
import Benefits from "@Common/Benefits/Benefits";
import PartnersCarousel from "@Common/PartnersCarousel/PartnersCarousel";
import Banner from "@Common/Banner/Banner";
import HowItWork from "@Common/HowItWork/HowItWork";
import FeedbackForm from "@Common/FeedbackForm/FeedbackForm";
import Footer from "@Common/Footer/Footer";

function App() {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <BrowserRouter>
      <div className="width-limit">
        {loading ? <Preloader/> :""}
        
        <Header/>
        <PromoBlock/>
        <Benefits/>
        <PartnersCarousel/>
        <Banner/>
        <HowItWork/>
        <FeedbackForm/>
      </div>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
