import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./HowItWork.scss";

import { useGlobalState } from "@/GlobalStateContext";

function HowItWork() {
  const { language, siteVersion } = useGlobalState();
  
  const { t: tSiteVersion } = useTranslation([`${siteVersion}`]);
  const [content, setContent] = useState(() =>
    tSiteVersion("howItWork", { returnObjects: true })
  );

  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAnswer = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    setContent(tSiteVersion("howItWork", { returnObjects: true }));
  }, [language, tSiteVersion]);

  useEffect(() => {
    setActiveIndex(0);
  }, [siteVersion]);

  return (
    <div className="questions-container" id="howItWork">
      <h1>{tSiteVersion("howItWork.title")}</h1>

      <div className="question-answer-list">
        {content?.list?.map((item, index) => (
          <div className="question-answer-list-item" key={index}>
            <div className={`questions-label ${activeIndex === index ? "active" : ""}`} onClick={() => toggleAnswer(index)}>
              <p>{item.title}</p>
            </div>
            <div className={`answer-block ${activeIndex === index ? "active" : ""}`} dangerouslySetInnerHTML={{ __html: item.text }} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default HowItWork;
