import params from "../../params.json";
import { socialMediaLinks } from '@consts/socialMediaLinks.js';

const projectCode = process.env.REACT_APP_PROJECT_CODE || "test";

const {
  SERVICE_URL: serviceUrl,
  SOURCE_URL: sourceUrl,
  LK_URL: lkUrl,
  BACKOFFICE_URL: backOfficeUrl,
  EMAIL_URL: emailUrl,
  REQUEST_PARAMS: requestParams,
} = params[projectCode];

if (!lkUrl || !backOfficeUrl || !sourceUrl) {
  throw new Error("Invalid env params");
}

const headers = {
  'Content-type': 'application/json; charset=utf-8',
};

const requestBody = {
  ...requestParams,
};

const createSession = () => {
	return new Promise( async (resolve, reject) => {
    try {
      const response = await fetch( `${serviceUrl}/commonApi/Session`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          "SourceUrl": requestParams.Source === 'PDL' ?  document.location.href : sourceUrl
        })})

      const data = await response.json()

      if ( response.ok ) resolve( data )
      else reject()  
    } catch ( e ) {
      reject( e )
    }
	})
}

const getInitialCalcData = () => {
  return new Promise(async (resolve, reject) => {
    const sessionID = localStorage.getItem('guid') 
    try {
      const response = await fetch(`${serviceUrl}/commonApi/pdlParams/${sessionID}`);
      const data = await response.json();
      if (response.ok) resolve(data);
      else reject();
    } catch (e) {
      reject(e);
    }
  });
};

const getCalculatedDataByParams = ({ Amount, Term }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        Amount,
        Term,
        ...requestBody,
      };
      const response = await fetch(`${serviceUrl}/commonApi/pdlParams`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          ...headers,
          SessionId: localStorage.getItem('guid'),
        },
      });
      const data = await response.json();
      if (response.ok) resolve(data);
      else reject();
    } catch (e) {
      reject(e);
    }
  });
};

const getRedirectToServiceLinkByParams = ({ Amount, Term  }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const body = {
        Amount,
        Term,
        ...requestBody,
      };
      const response = await fetch(`${serviceUrl}/commonApi/preOrder`, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          ...headers,
          SessionId: localStorage.getItem('guid'),
        },
      });
      const data = await response.json();
      if (response.ok) resolve(data);
      else reject();
    } catch (e) {
      reject(e);
    }
  });
};

const sendMail = ({ subject, text, emailTo, pinnedFiles }) => {
  return new Promise(async (resolve, reject) => {
    const payload = {
      subject,
      text,
      emailTo: emailTo ?? socialMediaLinks.email,
      pinnedFiles,
    };
    try {
      const response = await fetch(`${emailUrl}/feedback/email`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          ...headers,
        }
      });
      const data = await response.text();
      if (response.ok) resolve(data);
      else reject();
    } catch (e) {
      reject(e);
    }
  });
}

const sendFeedback = async ({ phoneNumber, name, organization }, siteVersion, email) => {
  const messengers = {
    client: {
      subject: `Заявка на обратный звонок от клиента ${phoneNumber}`,
      text: `Заявка на обратный звонок от клиента ${phoneNumber}`,
    },
    business: {
      subject: `Заявка на подключение партнера ${organization}`,
      text: `Заявка на подключение партнера ${organization}, контакт ${name} ${phoneNumber}`,
    }
  }

  return await sendMail({
    ...messengers[siteVersion],
    emailTo: email
  });
};

const sendComplaint = ({ name, surname }, pinnedFiles, email) => {
  const text = `Estimado ${name} ${surname} su recurso se estudiará en un plazo de treinta días. Espere una respuesta a su correo electrónico.`;
  const subject = "Reclamación sobre el producto servicio (DoctorSol)";
  const getParams = () => ({
    subject,
    text,
    emailTo: email,
    pinnedFiles
  })

  return new Promise(async (resolve, reject) => {
    try {
      const data = sendMail( getParams() );
      resolve(data);
    } catch (e) {
      reject(e);
    }
  });
};

export {
  serviceUrl,
  sourceUrl,
  lkUrl,
  backOfficeUrl,
  getInitialCalcData,
  getCalculatedDataByParams,
  getRedirectToServiceLinkByParams,
  sendFeedback,
  sendComplaint,
  createSession,
};
