import React, { useState, useEffect, useMemo } from "react";
import "./FeedbackForm.scss";
import { useTranslation } from "react-i18next";
import { useGlobalState } from "@/GlobalStateContext";
import { useMask } from "@react-input/mask";
import { sendFeedback } from "@services/api";
import validatePhoneNumber from "@utils/validatePhoneNumber";
import Loader from "@Common/Loader/Loader";

const DELAY_TIME = 3000;

const defaultParams = { phoneNumber: "", name: "", organization: "" };

function FeedbackForm() {
  const inputRef = useMask({
    mask: "+998 __ ___ __ __",
    replacement: { _: /\d/ },
  });

  const { language, siteVersion } = useGlobalState();
  const { t: tCommon } = useTranslation(["common"]);

  const isClient = siteVersion === "client";

  const requiredFields = useMemo(
    () =>
      new Set(
        isClient ? ["phoneNumber"] : ["phoneNumber", "name", "organization"]
      ),
    [isClient]
  );

  const [params, setParams] = useState(defaultParams);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");

  const onChangeParam = (event) => {
    const { name, value } = event.target;
    setParams((prevParams) => ({
      ...prevParams,
      [name]: value,
    }));
    clearError(name);
  };

  const clearError = (fieldName) => {
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[fieldName];
      return updatedErrors;
    });
  };

  const validateForm = () => {
    const errors = {};

    Object.keys(params).forEach((field) => {
      if (!params[field] && requiredFields.has(field)) {
        errors[field] = tCommon("feedbackForm.messages.requiredField");
      }
    });

    if (params.phoneNumber.length && !validatePhoneNumber(params.phoneNumber)) {
      errors.phoneNumber = tCommon("feedbackForm.messages.invalidPhoneNumber");
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendFormInformation = async () => {
    if (!validateForm()) return;

    setIsLoading(true);

    try {
      await sendFeedback(params, siteVersion);
      setSuccessMessage(tCommon("feedbackForm.messages.success"));
    } catch (error) {
      console.error(error);
      setErrors({ globalError: tCommon("feedbackForm.messages.globalError") });
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setParams(defaultParams);
        setSuccessMessage("");
        setErrors({});
      }, DELAY_TIME);
    }
  };

  useEffect(() => {
    setParams(defaultParams);
    setErrors({});
  }, [siteVersion, language]);

  return (
    <div className="feedback-container" id="feedback-form">
      <div className="feedback-text">
        <h2>{tCommon("feedbackForm.title")}</h2>
        <p dangerouslySetInnerHTML={{ __html: tCommon("feedbackForm.text") }} />
      </div>

      <div className="feedback-form">
        <div className="input-container">
          {!isClient && (
            <>
              <span>{tCommon("feedbackForm.fields.name")}</span>
              <input
                type="text"
                name="name"
                placeholder={tCommon("feedbackForm.fields.name")}
                value={params.name}
                onChange={onChangeParam}
              />
              {errors.name && <span className="error-message">{errors.name}</span>}
            </>
          )}

          <span>{tCommon("feedbackForm.fields.telephone")}</span>
          <input
            ref={inputRef}
            type="text"
            name="phoneNumber"
            placeholder="+998 99 999 99 99"
            value={params.phoneNumber}
            onChange={onChangeParam}
          />
          {errors.phoneNumber && <span className="error-message">{errors.phoneNumber}</span>}

          {!isClient && (
            <>
              <span>{tCommon("feedbackForm.fields.organization.title")}</span>
              <input
                type="text"
                name="organization"
                placeholder={tCommon("feedbackForm.fields.organization.placeholder")}
                value={params.organization}
                onChange={onChangeParam}
              />
              {errors.organization && <span className="error-message">{errors.organization}</span>}
            </>
          )}
        </div>

        {(errors.globalError || successMessage) && <div className="message">{errors.globalError || successMessage}</div>}

        <button
          className={`mobile-menu ${!isClient ? "buisness" : ""}`}
          onClick={sendFormInformation}
          disabled={isLoading}
        >
          {isLoading ? <Loader /> : tCommon("feedbackForm.fields.button.text")}
        </button>
      </div>
    </div>
  );
}

export default FeedbackForm;
