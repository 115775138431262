import React, { createContext, useContext, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const { i18n } = useTranslation();

  const storedLanguage = sessionStorage.getItem('selectedLanguage') || 'uz';
  const storedVersion = sessionStorage.getItem('selectedVersion') || 'client';

  const [language, setLanguage] = useState(storedLanguage);
  const [siteVersion, setSiteVersion] = useState(storedVersion);

  useEffect(() => {
    sessionStorage.setItem('selectedLanguage', language);
    sessionStorage.setItem('selectedVersion', siteVersion);

    i18n.changeLanguage(language);
  }, [language, siteVersion]);

  const value = {
    language,
    setLanguage,
    siteVersion,
    setSiteVersion,
  };

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};
