import React from 'react';

import "./Preloader.scss";

const Preloader = () => {

  return (
    <div className="preloader-container">
      <svg className="preloader-svg" width="92" height="93" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Mark" clipPath="url(#clip0_909_13788)">
          <path id="Vector" d="M13.0788 0.90625C3.48061 0.90625 1.12402 14.0884 1.12402 21.2809C1.12402 27.6633 3.5788 34.2421 8.75837 32.671C6.22995 29.9708 5.29714 24.4966 5.29714 20.9372C5.29714 15.7822 6.69636 5.39848 13.3979 5.39848C18.9457 5.39848 26.7028 12.4191 26.7028 17.9669C26.7028 21.9191 20.5167 27.516 15.9754 27.516C14.7235 27.516 13.987 25.8197 13.6213 23.0974C16.0589 23.7258 22.3578 21.5484 22.3578 17.8417C22.3578 14.2332 17.7428 10.2319 14.3062 10.2319C10.4522 10.2319 9.47025 16.197 9.47025 20.1001C9.47025 24.7642 9.96121 31.5148 15.5335 31.5148C21.8669 31.5148 30.8759 23.9787 30.8759 17.9154C30.8759 9.63052 20.3449 0.90625 13.0788 0.90625ZM13.4224 19.4152C13.4224 18.2124 13.4961 14.4321 15.0426 14.4321C16.1718 14.4321 18.3811 16.3468 18.3811 17.5987C18.3811 19.4398 15.3617 20.2499 13.4224 20.2499V19.4152Z" fill="#EB3B66"/>
        </g>
        <defs>
          <clipPath id="clip0_909_13788">
            <rect width="32" height="32" fill="white" transform="translate(0 0.90625)"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Preloader;
